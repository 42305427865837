.truncated-text {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.expandable-text {
	display: inline;
}

.expandable-text-ellipsis {
	display: inline;
}

.expandable-text-button {
	margin-left: 5px;
	text-transform: lowercase;
}

.expandable-text-button:hover {
	background: none;
}
