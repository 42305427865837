.IntroductionContainer {
	max-width: 90vw;
	width: 100%;
}

.IntroductionContainer div {
	width: 100%;
	border-radius: 16px;
}

.IntroductionContainer img {
	height: 100%;
	width: 100%;
	max-width: 100%;
	max-height: 60vh;
	object-fit: cover;
	border-radius: 16px;
}

.slick-dots li button::before {
	color: #ababab;
	font-size: 8px;
}

.slick-dots li.slick-active button::before {
	color: #a9a9a9;
}

.Banner {
	width: 100%;
	max-width: 100%;
	height: 100vh;
	min-height: 50vh;
	min-width: 0;
}
